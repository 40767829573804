import { Body, MainContainer, Root, Title } from "../RGPDPage/styledComponents";
import BodyContainer from "../../atoms/BodyContainer";
import React from "react";

const CGVPage = () => (
  <Root>
    <MainContainer>
      <BodyContainer>
        <Title>
          Conditions générales de vente et d’utilisation applicables aux
          professionnels
        </Title>

        <Title>Préambule</Title>
        <Body>
          Les présentes conditions générales de vente et d’utilisation
          détaillent les modalités d’utilisation des services proposés par la
          société DAT, société par actions simplifiée, immatriculée au Registre
          du commerce et des Sociétés de Paris sous le numéro 827 776 162,
          siégeant au 259, rue Saint Honoré — 75001 Paris, et représentée par
          son Président Monsieur Philippe Allouche, (ci-après « SYMPL »). Le
          Site Internet et l’Application sont édités par SYMPL.
        </Body>
        <Title>1- Glossaire & définitions </Title>
        <Body>
          Les mots ci-dessous, dont la première lettre est en majuscules, seront
          définis comme tels dans les présentes conditions générales :
          Application : désigne l’Application logicielle Sympl, éditée par le
          Prestataire pouvant être installée sur le téléphone mobile du Client,
          à l’initiative de ce dernier. Client : personne physique ou morale
          utilisant les Services proposés par SYMPL à titre professionnel.
          Commande : désigne l’achat par le Client d’un des Services proposés
          par SYMPL dans les conditions suivantes. La Commande peut être passée
          par le Client de différentes façons via : - l’Application ; - le Site
          Internet ; - le Module : le module permet notamment au Client de
          connecter directement son site de vente au système de SYMPL. Toutes
          commandes générées sur son site de vente entrainent automatiquement
          l’ouverture d’une Commande dans le serveur de SYMPL; - Via email, le
          client adresse alors à SYMPL par email une demande de Services. Les
          Commandes peuvent être envoyées vers tous les pays du Monde. Coursier
          : désigne l’entité ou la personne responsable du retrait de la
          marchandise du Client, à l’adresse qu’il aura indiquée Entrepôt:
          désigne le ou les locaux occupés par SYMPL dans lesquels les objets
          confiés par les clients sont pesés, conditionnés et remis aux
          Transporteurs. Marchandise: Objets remis par le Client en vue de
          l’utilisation des services SYMPL. Module : désigne le connecteur entre
          le système de SYMPL et celui du Client. Objet : désigne les objets,
          produits ou marchandises que les Clients font livrer en utilisant les
          services proposés par SYMPL Partenaire : désigne l’ensemble des
          transporteurs partenaires utilisés par SYMPL. Les transporteurs sont
          La Poste, Colissimo, Chronopost, DPD France, TNT, DHL, UPS, Colis
          Privé, Asendia, Mondial Relay, Spring, la Poste et Prevoté. Cette
          liste est susceptible d’évoluer. Notre système informatique
          sélectionne le partenaire adéquat en fonction des caractéristiques de
          la commande et du mode de livraison choisi par le Client. Politique de
          confidentialité : règles relatives à l’exploitation des données
          personnelles des Clients. La Politique de confidentialité détaille
          l’utilisation faite des données personnelles du Client du site, ainsi
          que les droits dont il dispose sur ces données. Prix : prix du service
          proposé par SYMPL. Les prix sont entendus HT. Le Prix comprend le prix
          de collecte de la Marchandise, le prix de la collecte, le prix du
          transport et, le cas échéant, le prix de stockage et de la logistique.
          Service : désigne les prestations fournies par SYMPL au choix du
          Client. SYMPL propose les services suivants : - Collecte de la
          Marchandise et envoi à l’adresse mentionnée par le Client : dans le
          cadre de ce service, la Marchandise est préparée par le Client dans
          des sachets de collecte. Elle est collectée par SYMPL à l’adresse
          mentionnée par le Client et envoyée vers l’Entrepôt. Une fois
          réceptionnée, SYMPL emballe la marchandise dans son entrepôt et la
          fait livrer à l’adresse indiquée par le Client selon le mode de
          transport choisi par le Client (envoi express, standard, ...) ; - Un
          service plus global (« service Fullfilment »), comprenant une
          prestation logistique et de stockage de la Marchandise : dans le cadre
          de ce service, SYMPL se charge de réceptionner la Marchandise du
          Client dans son Entrepôt. La Marchandise, destinée à être envoyée plus
          tard, est stockée dans l’Entrepôt dans les conditions décrites
          ci-dessous. Au fur et à mesure des Commandes, la Marchandise est
          préparée et envoyée par SYMPL à l’adresse mentionnée par le Client,
          selon le mode de transport sélectionné par le Client. Dans le cadre de
          ce service, SYMPL assure le suivi des envois et des colis retournés en
          cas d’impossibilité de présentation (destinataire inconnu à l’adresse
          mentionnée (NPAI) ou absent lors de la présentation de la Marchandise
          ou colis retournés pour échange de l’Objet) ; - Un service à la
          demande : dans le cadre de ce service SYMPL peut, à la demande du
          Client, se charger d’emballer la Marchandise dans un emballage
          spécifique (par exemple, un emballage portant le logo du Client) ou
          procéder à l’envoi de la Marchandise selon un mode de transport
          spécifique ou un transporteur spécifique identifié par le Client etc.
          Site : désigne le site internet de SYMPL Transporteur : désigne la
          société de transport chargée de la livraison de la Marchandise de
          l’Entrepôt SYMPL à l’adresse mentionnée par le Client Validation de la
          commande : acceptation de la Commande passée par le Client sur
          l’Application, le Site ou le Module.
        </Body>
        <Title>2- Champ d'application </Title>
        <Body>
          Conformément aux dispositions de l’article L 441-6 du Code de
          Commerce, les présentes conditions générales de vente et d’utilisation
          constituent le socle unique de la relation commerciale entre les
          parties et s’appliquent sans restriction ni réserves à toutes les
          commandes de Service défini à l’article 3 ci-dessous passées par le
          Client notamment sur l’application, le site internet, le module SYMPL
          ou simplement par emails. Les présentes conditions de vente et
          d’utilisation s’appliquent à l’exclusion de toutes autres conditions.
          Leurs stipulations prévalent sur toutes mentions contraires issues des
          documents du Client et notamment de ses conditions générales d’achat.
          Elles ont pour objet de définir les conditions générales dans
          lesquelles SYMPL fournit son Service à ses Clients. Seules les
          stipulations des conditions particulières prévaudront, en cas de
          contradiction, sur les présentes. Elles sont accessibles à tout moment
          sur l’Application, le site internet et le module et prévaudront, le
          cas échéant, sur toute autre version ou tout autre document
          contradictoire. Le Client est tenu d’en prendre connaissance avant
          toute passation de commande, le choix et l’achat d’un service étant de
          la seule responsabilité du Client. Le Client déclare avoir pris
          connaissance des présentes conditions générales de vente et
          d’utilisation et les avoir acceptées. La validation de la commande de
          Service par le Client vaut acceptation sans restriction ni réserve des
          présentes conditions générales de vente et d’utilisation. Ces
          conditions pouvant faire l’objet de modifications ultérieures, la
          version applicable à la Commande du Client est celle en vigueur sur
          l’Application, le site ou le Module à la date de passation de la
          Commande. Si l’une des clauses s’avérait, pour quelque raison que ce
          soit, inapplicable, nulle ou non valide, le retrait de ladite
          disposition n’affecterait pas la validité et l’opposabilité des autres
          stipulations du contrat dans son ensemble. Sauf preuve contraire, les
          données enregistrées dans le système informatique de SYMPL constituent
          la preuve de l’ensemble de la transaction conclue avec le Client.
        </Body>
        <Title>3- Informations sur le Service SYMPL</Title>
        <Body>
          Les Services SYMPL ont pour vocation de proposer une solution
          logistique à la demande simplifiée, en permettant aux professionnels
          d’externaliser leur système logistique. Dans le cas où le Client
          utilise les Services via l’Application : le Client procède à la remise
          de ses Marchandises au Coursier. Le Client s’engage à être présent à
          l’adresse indiquée. S’il s’avérait absent, le Coursier tentera de le
          joindre par téléphone au numéro indiqué lors de la commande sur
          l’Application. L’attente ne saurait excéder cinq (5) minutes, après
          lesquelles le Coursier pourra quitter le lieu de retrait et considérer
          la commande comme honorée. La pénalité prévue à l’article 9 ci-après
          sera alors appliquée. Dans tous les cas (que la Commande soit passée
          via l’Application ou non) : le Client s’engage à remettre à SYMPL la
          marchandise avec un code barre EAN permettant à SYMPL d’identifier les
          colis à leur arrivé à l’entrepôt. SYMPL sera contrainte de facturer au
          Client les frais d’impression et de pose des codes-barres EAN en cas
          de manquement du Client ou si ces derniers étaient illisibles. En cas
          de commandes multiples, le Client s’engage à séparer les commandes les
          unes des autres. A défaut, SYMPL se réserve le droit d’en refuser la
          collecte. SYMPL décline toute responsabilité quant à une éventuelle
          erreur effectuée dans les envois de commande dès lors qu’elle serait
          la conséquence directe d’un défaut d’individualisation des produits du
          Client. Le Client s’engage à fournir des informations justes et
          réelles sur ses marchandises, notamment (sans que cette liste soit
          exhaustive) sur la quantité et la valeur des produits. Le Client
          s’engage à ne pas demander l’exécution des Services proposés par SYMPL
          pour des produits interdits par l’article 7 des présentes Conditions
          générales. Une fois arrivée à l’entrepôt de SYMPL, les marchandises
          sont scannées et rangées. SYMPL n’effectue aucun contrôle sur la
          qualité de la marchandise laquelle est réputée arrivée dans l’état
          dans lequel le Client l’a adressée, la responsabilité de SYMPL ne
          pourra en aucun cas être engagée s’il s’avérait que la marchandise
          était défectueuse, SYMPL n’étant pas responsable de la livraison à son
          entrepôt de ladite marchandise. Le cas échéant la marchandise est
          stockée dans les locaux de SYMPL dans l’attente des instructions du
          Client. Il revient au Client de veiller aux éventuelles dates de
          péremption de ses produits et de faire en sorte que les produits
          stockés ne dépassent pas les dates de péremption. Les produits
          présents dans l’entrepôt de SYMPL seront considérés par SYMPL comme «
          livrables » sauf mention express contraire du Client. La Marchandise «
          non livrable » devra impérativement être récupérée par le Client qui
          devra alors passer une Commande afin que la Marchandise lui
          soitretournée. Le Transporteur achemine le colis à la destination
          indiquée par le Client. Le Client s’engage à fournir des adresses
          précises et réelles de destination. SYMPL ne pourra être tenu
          responsable en cas d’échec de livraison d’un de ses Partenaires. Si
          l’adresse correspond à celle d’un professionnel, au moins un membre du
          personnel doit être présent aux heures normales de bureau pour
          réceptionner l’envoi. Dès la prise en charge de l’Objet par le
          Transporteur un message sera adressé au Client pour lui indiquer le
          nom du Partenaire, son site et les références avec lesquelles il
          pourra suivre l’évolution de la livraison de son Objet. Si pour une
          raison indépendante de la volonté du Transporteur, la livraison ne
          pouvait pas être réalisée, notamment l’absence de destinataire à
          l’adresse indiquée, un avis de passage sera déposé sur lequel sera
          indiqué le lieu où l’Objet pourra être retiré. A défaut de retrait de
          l’Objet dans un délai de 10 jours à compter du dépôt de l’avis de
          passage, l’Objet sera réexpédié dans les locaux de SYMPL. Le Client
          pourra alors venir le récupérer sous réserve de régler les pénalités
          prévues à l’article 9 ci-dessous. Le Client recevra chaque jour un
          rapport sur les Marchandises expédiées par SYMPL et retournées par le
          client final (produits défectueux ou produits retournés par le client
          final dans le cadre de l’usage de son droit de rétractation). Il
          revient au Client de suivre l’état du stock des Marchandises dans
          l’entrepôt SYMPL (notamment date de péremption et produits défectueux)
          et de demander à SYMPL, en initiant une Commande, le retour de ces
          produits afin qu’ils ne restent pas entreposés chez SYMPL étant
          rappelé que tous les produits entreposés chez SYMPL sont réputés en
          état d’être livrés. Si la Marchandise venait à n’ être ni réclamée ni
          récupérée par le Client dans un délai de 3 mois à compter de sa
          restitution à SYMPL, SYMPL est d’ores et déjà autorisé à le détruire
          ou à le vendre.
        </Body>
        <Title>4 - Engagements du Client</Title>
        <Body>
          Le Client s’engage à : - se conformer aux dispositions des présentes
          Conditions générales. - respecter les lois et règlements en vigueur
          dans tous les pays concernés par le transport des Objets acheminés par
          SYMPL. - ne pas perturber ou entraver le bon fonctionnement de
          l’Application, du Module ou du Site Internet. - fournir les
          informations exactes nécessaires à l’utilisation du Service. Toutes
          les données récoltées dans le cadre du Service constituent des preuves
          recevables et opposables entre les parties dans le cadre d’une
          procédure judiciaire. - payer les sommes dues à SYMPL au titre des
          Services commandés. - ne faire qu’un usage strictement personnel des
          Services de SYMPL. La revente des Services à un tiers est strictement
          prohibée. - ne pas céder ou transférer le compte Client, nom du Client
          et mot de passe, à un tiers, personne physique ou morale. La
          confidentialité et la fiabilité du mot de passe et du nom du Client
          sont de l’unique responsabilité du Client. - à fournir toute preuve
          d’identité si SYMPL le juge raisonnablement opportun et nécessaire. -
          utiliser le Service en conformité avec sa finalité. - ne pas utiliser
          le Service à des fins de nuisance envers un tiers. - ne pas copier ou
          distribuer le contenu de SYMPL, de quelque manière que ce soit, sans
          avoir préalablement obtenu l’accord écrit de ses dirigeants.
        </Body>
        <Title>5 - Prix et modalités de paiement</Title>
        <Title>5.1 Détermination du prix</Title>
        <Body>
          Le prix facturé correspond d’une part au prix relatif à la collecte de
          la marchandise chez le Client et, d’autre part, au prix de livraison
          de la marchandise au lieu indiqué par le Client lors de la Commande.
          SYMPL adressera donc au Client deux factures distinctes, l’une
          correspondant à la collecte des marchandises, et le cas échéant aux
          frais complémentaires d’impression et de collage du code- barres EAN,
          et l’autre à la livraison. Pour le service Fullfilment, le Client sera
          également facturé tous les mois des frais de logistique et de stockage
          sans pouvoir toutefois être inférieur à 250 € HT par mois (ceci étant
          le montant minimum facturé tous les mois par SYMPL au Client). Les
          Services proposés par SYMPL sont fournis aux tarifs en vigueur lors de
          l’enregistrement de la commande. Dans le cadre des Commandes
          internationales, des droits de douanes ou autres taxes locales ou
          droits d’importation ou taxes d’état sont susceptibles d’être
          exigibles. Ils seront à la charge et relèvent de la seule
          responsabilité du Client. Le Client est informé de ce que le prix de
          livraison peut être amené à varier par rapport à la grille tarifaire
          qui lui aurait été remise notamment en raison des coûts
          supplémentaires facturés par le Transporteur comme (cette liste
          n’étant pas exhaustive) la taxe carburant, le surcoût pour livraison
          sur une zone d’accès considérée comme difficile, en cas de deuxième
          passage du fait de l’absence du destinataire lors de la première
          présentation du colis, surcoût en cas de demandes particulières du
          Client (par exemple demande d’un Transporteur en particulier par
          rapport à celui initialement sélectionné par SYMPL ou si le
          Transporteur sélectionné n’assure plus le transport, pour quelques
          raisons que ce soit et que SYMPL est obligé de recourir au service
          d’un autre Transporteur dont les coûts de transport sont plus élevés).
        </Body>
        <Title>5.1.1. Détermination du prix estimatif :</Title>
        <Body>
          Le prix estimatif communiqué au Client préalablement à la validation
          de sa commande est présenté sous forme de fourchette (prix minimum
          estimé – prix maximum estimé). Le prix estimatif est déterminé
          notamment en fonction des informations qui ont été communiquées par le
          Client : poids, dimensions et photo.
        </Body>
        <Title>5.1.2. Détermination du prix définitif</Title>
        <Body>
          Le prix définitif est calculé en fonction de la grille tarifaire de
          SYMPL et tient compte des dimensions exactes et du poids effectif de
          l’objet transporté. Il peut être différent du prix estimatif
          communiqué lors de la Commande. A la demande du Client, SYMPL pourra
          fournir la facture du Transporteur indiquant le poids de la
          marchandise transporté et les preuves des éventuels surcoûts appliqués
          par le Transporteur comme indiqué ci-dessus. SYMPL garantit la stricte
          confidentialité des données bancaires transmises. SYMPL s’engage à
          n’en faire aucun usage de quelque nature que ce soit.
        </Body>
        <Title>5.2. Détermination du prix de stockage</Title>
        <Body>
          Le prix du stockage de la marchandise est déterminé en fonction des
          mètres carrés utilisés par le Client dans l’entrepôt de SYMPL. Le prix
          est fixé dans les conditions particulières conclues entre SYMPL et le
          Client. Le stockage de la marchandise fait l’objet d’une facture
          distincte adressée au Client.
        </Body>
        <Title>5.3. Modalités de règlement</Title>
        <Body>
          Lorsque la Commande de Service est réalisée via l’Application, le
          Client est facturé et prélevé directement via l’Application. Pour
          toutes les Commandes de Service passées autrement que via
          l’Application, une facture est émise à l’ordre du Client tous les mois
          pour l’ensemble des Services réalisés par SYMPL au cours du mois
          écoulé, hors service de stockage. Elle est alors payable par
          prélèvement sur le compte en banque du Client, le 15 de chaque mois.
          Les données de paiement sont échangées en mode crypté grâce au
          protocole HTTPS. En cas de non paiement d’une facture, SYMPL se
          réserve le droit de suspendre l’exécution des Services pour les mois
          suivants et cela tant que la ou les factures restera(ont) impayée(s).
          Les paiements effectués par le Client ne seront considérés comme
          définitifs qu’après encaissement effectif des sommes dues.
        </Body>
        <Title>5.4. Retard de paiement-pénalités</Title>
        <Body>
          En cas de retard de paiement et de versement des sommes dues à SYMPL
          au-delà des délais fixés ci-dessus et/ou dans les conditions
          particulières, des pénalités de retard égales au taux d’intérêt
          appliqué par la Banque Centrale Européenne à son opération de
          refinancement la plus récente majoré de DIX (10) points de pourcentage
          du montant TTC du prix figurant sur ladite facture, seront
          automatiquement et de plein droit acquises à SYMPL sans formalité
          aucune ni mise en demeure préalable. Enfin, une indemnité forfaitaire
          pour frais de recouvrement, d’un montant de 40 € sera due en
          supplément, de plein droit et sans notification préalable en cas de
          retard de paiement. SYMPL se réserve le droit de demander au Client
          une indemnisation complémentaire si les frais de recouvrement
          effectivement engagés dépassaient ce montant, sur présentation des
          justificatifs. La prolongation d’un défaut de paiement d’une facture
          plus d’un mois après sa date entraîne : - De plein droit et à la
          discrétion de SYMPL la suspension de toute prestation ultérieure; -
          L’exigibilité immédiate et de plein droit de toutes autres factures
          non encore échues, avec application de l’intérêt de retard précité à
          compter de la date d’exigibilité immédiate ; - A compter de
          l’intervention de tout auxiliaire de justice ou juridiction,
          l’application d’une indemnité forfaitaire de 15% de l’ensemble des
          sommes dues à titre de clause pénale, sans préjudice de tous dommages
          et intérêts. Il est précisé qu’un règlement n’est réputé effectué que
          par son encaissement effectif au crédit du compte bancaire de SYMPL,
          et à compter de celui-ci. La remise de tout effet de commerce ou
          promesse équivalente de paiement ne constitue pas un règlement au sens
          du présent article.
        </Body>
        <Title>6- Réalisation de la prestation</Title>
        <Body>
          La livraison est assurée par les transporteurs sélectionnés par SYMPL
          dans les délais déterminés en fonction des options choisies. SYMPL
          n’est en aucun cas responsable du non-respect par le Transporteur des
          délais de livraison indiqués au Client. Seules les livraisons dites «
          Express » sont garanties au Client. En cas de panne des systèmes
          informatiques de transport ou, en cas de grève du Transporteur
          initialement sélectionné, l’Objet sera envoyé au destinataire par un
          Transporteur offrant les mêmes niveaux de prestations. Dans ce cas,
          les frais supplémentaires que le changement de Transporteur pourrait
          engendrer seront à la charge du Client.
        </Body>
        <Title>7 – Refus légitime de commande</Title>
        <Body>
          SYMPL se réserve le droit de refuser le traitement de certaines
          commandes, notamment si elles émanent d’un Client insolvable et/ou
          avec lequel SYMPL a précédemment rencontré des différends dans le
          paiement de ses Services. Il en est de même en cas de violation des
          présentes Conditions générales. SYMPL ne pourra pas être tenue pour
          responsable si la non-exécution ou le retard dans l'exécution de l'une
          quelconque de ses obligations, telles que décrites dans les présentes
          découle d'un cas de force majeure, au sens de l'article 1218 du Code
          civil ou d'aléas sanitaires ou climatiques exceptionnels ou de
          décisions gouvernementales ou étatiques, indépendants de sa volonté.
          La suspension des obligations ne pourra en aucun cas être une cause de
          responsabilité pour non-exécution de l'obligation en cause, ni induire
          le versement de dommages et intérêts ou pénalités de retard. SYMPL se
          réserve également le droit de ne pas traiter les commandes relevant
          d’une tentative de fraude, ou du transport d’objets illicites tels que
          définis ci-dessous. SYMPL se réserve le droit de refuser le traitement
          des commandes dont les informations requises révélaient la présence de
          propos injurieux, inacceptables, ou illégaux. SYMPL informe son Client
          que ne pourront être pris en charge, sans que cette liste soit
          exhaustive - les bijoux et articles d’horlogerie composés même
          partiellement de pierres et métaux précieux, - les pierres et métaux
          précieux, - les monnaies, devises, billets de banque, valeurs
          financières, titres ou moyens de paiement et notamment les effets de
          commerce, les cartes téléphoniques, chèques cadeaux, ou tout autre
          support permettant un paiement fongible, - l’intégralité des articles
          relevant des dispositions des règlementations nationales et
          internationales sur les produits dangereux comme, sans que cette liste
          soit exhaustive: les gaz, munitions, matières inflammables,
          radioactives, toxiques, infectieuses ou corrosives, - les animaux ou
          êtres vivants ou morts, - les marchandises soumises à une certaine
          température, - les denrées périssables, - les armes à feu ou armes de
          guerre ou de collection, - les objets d’art ou antiquités, - tout
          objet qui, par leur conditionnement ou nature, sont susceptibles de
          présenter un danger pour le personnel de transport ou de logistique -
          les véhicules ou engins de transport, les tiers, - les supports
          audiovisuels interdits par toute loi ou règlementation applicable, -
          tout produit contrefait ou issu de la contrefaçon, - les objets
          immoraux, - les médicaments, - le tabac, - les stupéfiants, - les
          matières psychotropes, - les cendres ou reliques funéraires. En cas de
          violation de l’une de ces dispositions, SYMPL se réserve le droit
          d’abandonner l’acheminement de tout colis, et le Client s’engage à
          indemniser SYMPL de toutes les conséquences résultant de cette
          violation.
        </Body>
        <Title>8. Durée</Title>
        <Body>
          Le contrat cadre de partenariat conclu entre le Client et SYMPL aux
          termes duquel SYMPL met à la disposition du Client les Services SYMPL
          est conclu pour une durée indéterminée. Chaque partie peut y mettre
          fin à tout moment par l’envoi d’une lettre recommandée avec accusé de
          réception moyennant le respect d’un délai de préavis de trois (3) mois
          si la relation contractuelle dure depuis moins de 2 (deux) ans et un
          préavis de six (6) mois si la relation contractuelle dure depuis plus
          de 2 (deux) ans. Pendant la durée du préavis, les parties s’engagent à
          maintenir l’économie du contrat. De plus, par exception à ce qui
          précède, DAT pourra mettre fin à tout moment à la relation
          contractuelle, moyennant le respect d’un délai de préavis d’un (1)
          mois en deçà d’un montant de chiffre d’affaires éloigné de plus de 70%
          du prévisionnel inscrit dans le contrat. Une remise exceptionnelle
          applicable sur le prix des Service pourra être accordée au Client qui
          s’engagerait dès le début de la relation contractuelle à faire appel
          exclusivement aux Services de SYMPL pour une durée minimum. Le montant
          de la remise sera discuté entre les parties et dépendra notamment de
          la durée minimum sur laquelle le Client s’engage vis-à-vis deSYMPL.
        </Body>
        <Title>9. Pénalités</Title>
        <Body>
          Dans tous les cas de refus de commande au départ du destinataire ou à
          l’Entrepôt SYMPL, dans les conditions visées ci-dessus, une pénalité
          forfaitaire égale au coût du service SYMPL multipliée par le nombre
          d’objets à récupérer simultanément à la même adresse sera appliquée au
          Client. Le coût du service SYMPL est précisé dans les conditions
          particulières. En cas d’impossibilité de livrer du transporteur dû à
          la faute ou la négligence du Client (notamment, absence de
          destinataire et d’instructions pour nouvelle présentation) : le prix
          de la commande restera acquis à SYMPL majorée du montant des frais de
          retour facturé par le Transporteur par Objet que le Client devra
          régler afin que son ou ses Objet(s) lui soi(en)t restitué(s).
        </Body>
        <Title>10. Rétractation, modification, annulation de la commande</Title>
        <Body>
          Pour toutes les Commandes passées via l’Application : Toute annulation
          ou modification survenue plus d’un quart d’heure après la commande du
          Coursier entrainera la facturation d’une pénalité de CINQ (5) euros
          par objet. Dans tous les cas (c’est-à-dire Commande passée via
          l’Application ou non) : Les commandes ne pourront être ni annulées ni
          modifiées après la prise en charge des colis par le Partenaire sauf
          information contraire communiquée par le transporteur après sa mise en
          relation avec le Client. Dans ce cas, toute modification de la
          commande est susceptible d’en modifier le prix. En procédant à la
          modification de sa commande, le Client est réputé accepter le nouveau
          calcul de prix. L’annulation ou la modification de la commande n’est
          effective que lorsque le Client en aura reçu une confirmation. En
          aucun cas SYMPL pourrait être tenue pour responsable en cas
          d’impossibilité de modification de commande.
        </Body>
        <Title>11. Assurances & limitations de responsabilité</Title>
        <Body>
          Aucune assurance n'est souscrite par SYMPL au titre d’une Commande
          sans ordre préalable et express du Client, précisant les risques à
          couvrir et les valeurs à garantir.
        </Body>
        <Title>11.1. La livraison</Title>
        <Body>
          11.1.1 En cas de perte, vol, ou de dommage avéré du colis envoyés en
          France ou à l’étranger en « contre signature » ou en « express »,
          SYMPL s’engage, sur présentation de factures et de toute pièce
          justifiant la perte, le vol ou le dommage (photos des colis
          endommagés, déclaration de perte ou de vol), à rembourser à son Client
          la valeur du ou des Objet(s) dans la limite de trois cents (300) Euros
          par Commande. 11.1.2. En cas de perte, vol ou de dommage avéré du
          colis envoyé en France « sans signature », SYMPL s’engage, sur
          présentation de factures et de toute pièce justificative (photos des
          colis endommagés, déclaration de perte ou de vol), à rembourser à son
          Client la valeur du ou des Objet(s) dans la limite de cent (100) Euros
          par Commande. 11.1.3 Exclusions : Par exception à ce qui précède
          (paragraphe 11.1.1 et 11.1.2 ci-dessus), toutes pertes, vol, et/ou
          dommages avérés sur les produits dits « high tech » tels que les
          smartphones et autres produits informatiques, le Client ne sera
          remboursé qu’à la hauteur de ce que le contrat du Transporteur
          prévoit. En aucun cas, il ne pourra être demandé à SYMPL d’accorder au
          Client une indemnisation supérieure à celle prévue par le
          Transporteur. 11.1.4 Pourront seuls être indemnisés dans les
          conditions du présent article les pertes et dommages directs causés ce
          qui exclut le dédommagement des pertes de chance ou perte de gain.
          Pour tout Objet d’une valeur supérieure à trois cents (300) Euros, il
          est fortement recommandé au Client de souscrire une assurance
          complémentaire, dont le montant est calculé selon un pourcentage (%)
          de la valeur totale du colis. En cas de perte ou de dommage avéré, et
          sur présentation d’une facture, le Client, qui aurait souscrit
          l’assurance complémentaire, pourra percevoir une indemnisation dans
          les conditions prévues au contrat d’assurance complémentaire souscrit.
          SYMPL décline toute responsabilité relative à une erreur de saisie ou
          une insuffisance dans les informations communiquées par le Client ou
          insuffisance des garanties souscrites par le Client en dépit des
          recommandations de SYMPL. Le Client qui n’aurait pas souscrit une
          assurance complémentaire, ne pourra pas prétendre à une indemnisation
          autre que celle prévue ci-dessus. En cas de survenance d’un sinistre,
          le Client s’engage : - à indiquer sur le document présenté par le
          transporteur lors de la livraison toutes les réserves, décrire les
          dommages, préciser la date le lieu ; - à en informer SYMPL dans les 48
          heures de la livraison par lettre recommandée avec accusé de réception
          accompagné de tous les justificatifs et références nécessaires, afin
          que SYMPL en avertisse immédiatement son partenaire. Le fait que SYMPL
          se présente comme intermédiaire entre le Client, le Coursier, le
          Logisticien et le Transporteur ne saurait engager sa responsabilité de
          quelque manière que ce soit. Nonobstant ces considérations, SYMPL
          s’engage à fournir ses meilleurs efforts en vue de faire assurer
          l’exécution de la commande du Client, et d’apporter la solution
          adaptée à la mauvaise exécution ou l’inexécution des prestations de
          ses partenaires, sans que ses efforts ne s’analysent comme
          constitutifs de l’engagement d’une responsabilité de quelque nature
          que ce soit.
        </Body>
        <Title>11.2. Le stockage</Title>
        <Body>
          SYMPL ne prendra aucune mesure pour vérifier que la marchandise est
          adaptée à un entreposage. SYMPL ne pourra être tenue responsable des
          pertes et dommages subis par le Client du fait d’un entreposage
          inapproprié. SYMPL décline toute responsabilité résultant de dommages
          ou détériorations subies par les produits périssables qui auraient été
          remis à SYMPL en vue de leur stockage préalablement à leur livraison,
          de même pour les produits revenus du fait de l’impossibilité de les
          livrer. Le Client s’engage à souscrire, et à maintenir pendant toute
          la durée de la période stockage des Marchandises, auprès d’une
          compagnie d’assurance notoirement solvable, une police d’assurance
          garantissant ses biens contre les risques assurables dont notamment
          les risques incendie, explosion, vol, dégâts des eaux. La police
          d’assurance devra contenir une clause de renonciation de l’assureur du
          Client contre la société DAT, ses assureurs et ses co-contractants.
          Faute de justifier auprès de la société DAT d’une telle assurance, le
          Client sera tenu d’adhérer à la police d’assurance tous risques
          souscrite par la société DAT pour le compte du Client. Si la
          responsabilité personnelle de SYMPL était engagée du fait d’une faute
          dûment prouvée, sa responsabilité serait limitée aux pertes et
          dommages directs causés aux Objets, ce qui exclut le dédommagement des
          pertes de chance ou perte de gain et serait, en tout état de cause,
          limitée à la somme totale, tous préjudices confondus, de trente-mille
          (30.000) euros.
        </Body>
        <Title>
          11.3. Sinistre relatif à la mauvaise utilisation des Services par le
          Client
        </Title>
        <Body>
          Pour toutes les Commandes passées via l’Application : Toute annulation
          ou modification survenue plus d’un quart d’heure après la commande du
          Coursier entrainera la facturation d’une pénalité de CINQ (5) euros
          par objet. Dans tous les cas (c’est-à-dire Commande passée via
          l’Application ou non) : Les commandes ne pourront être ni annulées ni
          modifiées après la prise en charge des colis par le Partenaire sauf
          information contraire communiquée par le transporteur après sa mise en
          relation avec le Client. Dans ce cas, toute modification de la
          commande est susceptible d’en modifier le prix. En procédant à la
          modification de sa commande, le Client est réputé accepter le nouveau
          calcul de prix. L’annulation ou la modification de la commande n’est
          effective que lorsque le Client en aura reçu une confirmation. En
          aucun cas SYMPL pourrait être tenue pour responsable en cas
          d’impossibilité de modification de commande.
        </Body>
        <Title>11.4 Prestations de logistique</Title>
        <Body>
          Les frais de logistique d’une Commande, facturés au Client par SYMPL,
          lui seront remboursés dans les seuls cas suivants : - Erreur de
          préparation d’une Commande imputable à SYMPL ; - Commande arrivée
          avant le « cut off » et non envoyée par SYMPL au plus tard le deuxième
          jour suivant ; - Commande arrivée après le « cut off » et non envoyée
          par SYMPL au plus tard le troisième jour suivant. Pour les envois
          Express exclusivement, SYMPL remboursera au Client, en plus des frais
          de logistique les frais de transport facturés dans les seuls cas
          suivants : - Commande arrivée avant le « cut off » et non envoyée par
          SYMPL au plus tard le premier jour suivant ; - Commande arrivée après
          le « cut off » et non envoyée par SYMPL au plus tard le deuxième jour
          suivant. Le « cut off » correspond à 12h00 (midi).
        </Body>
        <Title>12. Droit de gage conventionnel</Title>
        <Body>
          Quelle que soit la qualité en laquelle SYMPL intervient, le Client lui
          reconnaît expressément un droit de gage conventionnel emportant droit
          de rétention et de préférence général et permanent sur toutes les
          Marchandises, valeurs et documents en sa possession ou celle du
          Transporteur, et ce en garantie de la totalité des créances (factures,
          intérêts, frais engagés, etc.) que SYMPL détient contre lui, même
          antérieures ou étrangères aux opérations effectuées au regard des
          Marchandises, valeurs et documents qui se trouvent effectivement entre
          ses mains.
        </Body>
        <Title>13. Loi applicable et compétence juridictionnelle</Title>
        <Body>
          Les présentes Conditions générales sont régies par la loi française.
          Le Client qui souhaiterait assigner pour quelque raison que ce soit,
          s’engage à en informer préalablement SYMPL, et de fournir ses
          meilleurs efforts en vue de régler le litige à l’amiable. Si aucun
          accord ne s’avère envisageable, les litiges seront portés devant le
          Tribunal de commerce de Paris.
        </Body>
        <Title>14. Propriété intellectuelle</Title>
        <Body>
          L’intégralité des éléments de l’Application, du Site et du Module et
          notamment, sans que cette liste soit exhaustive, les photographies,
          graphiques, images, codes, mises en forme, textes, illustrations,
          arborescences, relèvent de la propriété intellectuelle exclusive de
          SYMPL. En vertu des dispositions de l’article L. 122-4 du Code de la
          propriété intellectuelle, et que ce soit à titre commercial ou non, la
          représentation, rediffusion, imbrication ou reproduction, intégrale ou
          partielle, et, plus généralement, toute exploitation non autorisée, et
          œuvre dérivée, de l’intégralité des éléments de l’Application, sont
          illicites. « SYMPL » est une marque déposée. Toute Utilisation de son
          nom, ou son logo, nécessite l’accord préalable de ses titulaires, sous
          peine d’Application des articles L. 713-2 et suivants du Code de la
          propriété intellectuelle. Le Client s’engage à respecter l’intégralité
          des droits de propriété intellectuelle de SYMPL s’expose à des
          poursuites judiciaires, et notamment à la caractérisation du délit de
          contrefaçon (sans que ce dernier soit exhaustif) dont les peines sont
          reportées aux articles L. 335-2 et suivants du Code de la propriété
          intellectuelle, et engage sa responsabilité civile comme pénale. Les
          données nécessairement récoltées pour l’exécution de ses Services ne
          seront pas transférées en dehors du territoire de l’Union Européenne.
          Toutes les précautions utiles à la récolte des données à caractère
          personnel sont prises. Ces données sont conservées dans des conditions
          optimales de sécurité et dans le respect de toutes les dispositions
          légales et règlementaires en vigueur.
        </Body>
        <Title>15. Loi informatique et liberté</Title>
        <Body>
          En application de la loi n°78-17 du 6 janvier 1978 modifiée par la loi
          du 6 août 2004 dite « Informatique et Libertés », il est rappelé que
          les données nominatives qui sont demandées au Client sont nécessaires
          au traitement de sa commande et à l’établissement des
          factures,notamment. Ces données peuvent être communiquées aux
          éventuels partenaires du Vendeur chargés de l’exécution, du
          traitement, de la gestion et du paiement des commandes. Le traitement
          des informations communiquées par l’intermédiaire de l’application
          SYMPL a fait l’objet d’une déclaration auprès de la CNIL (déclaration
          n° 1873271 v 0). SYMPL récolte de manière loyale les données de son
          Client. SYMPL informe son Client qu’elle ne recueille aucune donnée
          dite « sensible » faisant apparaître de façon directe ou indirecte,
          ses origines raciales, ethniques, opinions politiques, philosophiques,
          religieuse, comme les données relatives à sa santé ou sa vie sexuelle,
          ainsi que toute appartenance syndicale. Le Client est informé et
          accepte que certaines de ses données personnelles soient récoltées en
          vue de la stricte exécution des Services qu’il a expressément
          commandés. En commandant sur l’Application, le Site ou le Module, via
          les formulaires proposés, le Client consent à la récolte et au
          traitement des données personnelles qu’il inscrit volontairement. Le
          responsable du traitement de ces données personnelles est SYMPL. Le
          traitement de ces données personnelles est exclusivement destiné à
          mieux connaître le Client afin de permettre l’exécution des Services.
          Aucune donnée à caractère personnel ne sera recueillie sans l’accord
          exprès et préalable. L’accord du Client est réputé exprès après le
          clic validant une Commande. Conformément à la loi informatique et
          liberté du 6 janvier 1978, le Client dispose à tout moment d’un droit
          d’accès, de rectification et d’opposition à l’ensemble de ses données
          personnelles en écrivant par courrier et en justifiant de son identité
          à : SAS DAT, 259, rue Saint Honoré 75001 Paris
        </Body>
      </BodyContainer>
    </MainContainer>
  </Root>
);

export default CGVPage;

import React from "react";
import styled from "styled-components";
import BodyText from "../../atoms/BodyText";
import TitleText from "../../atoms/TitleText";

export const Root = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
`;

export const MainContainer = styled.div`
  max-width: 1200px;
  padding: 30px 90px 30px 90px;
  @media (max-width: 900px) {
    padding: 10px 30px 10px 30px;
  }
`;

export const Body = styled(BodyText)`
  margin-top: 10px;
`;

export const Title = styled(TitleText)`
  font-size: 45px;
  margin-top: 15px;
`;
